import {AuthHelper} from "./AuthHelper";
import {User} from "../components/UserManagement/UserManagement";
import {UserType} from "../components/UserManagement/components/UserTypeSelect/UserTypeSelect";

const USER_ENDPOINT = `${process.env.REACT_APP_DELIVERYAPI_URL}/users/v2`

export async function getUsers(): Promise<User[]>{
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
        method: 'GET',
        headers: { Authorization: `Bearer ${userAuthTokens.accessToken}` }
    }
    try {
        const response = await fetch(`${USER_ENDPOINT}`, config)
        const users = await response.json()
        return users.map((user: any) => ({
            username: user.username,
            email: user.email,
            givenName: user.givenName,
            familyName: user.familyName,
            defaultStoreNumber: user.defaultStoreNumber,
            userType: user.userType,
            enabled: user.enabled,
            storeList: user.storeList,
            phoneNumber: user.phoneNumber,
            createdDate: user.createdDate
        }))
    } catch(e) {
        console.error(e)
        return Promise.reject(e)
    }
}

type AddUserRequest = {
    email: string,
    familyName: string,
    givenName: string,
    phoneNumber?: string,
    defaultStoreNumber: string,
    userType: string,
    storeList: string[],
    username?: string | null
}

export async function addUser(request: AddUserRequest): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()

    const config = {
        method: 'POST',
        body: JSON.stringify(request),
        headers: { Authorization: `Bearer ${userAuthTokens.accessToken}`, 'Content-Type': 'application/json' }
    }

    try {
        const response = await fetch(`${USER_ENDPOINT}/adminCreate`, config)
        if(!response.ok) {
            const responseJson = await response.json()
            return Promise.reject( await responseJson.message)
        }
    } catch (e) {
        return Promise.reject(e)
    }
}

export async function batchImportUsers(file: File): Promise<void>  {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    //Convert File type to form data
    let formData = new FormData()
    formData.append('file', file)

    const config = {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${userAuthTokens.accessToken}`
        }
    }

    try {
       const response =  await fetch(`${USER_ENDPOINT}/batchAdminCreate`, config)
       return await response.json();
    } catch (e) {
        return Promise.reject(e)
    }
}

type EditDriverRequest = {
    email: string,
    givenName: string,
    familyName: string,
    phoneNumber?: string,
    defaultStoreNumber: string,
    storeList: string[],
    userType: UserType,
    username: string
}

export async function editDriver(username: string, editDriverRequest: EditDriverRequest): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
        method: 'PUT',
        body: JSON.stringify(editDriverRequest),
        headers: {
            Authorization: `Bearer ${userAuthTokens.accessToken}`, 'Content-Type': 'application/json'
        }
    }

    try {
        const response = await fetch(`${USER_ENDPOINT}/username/${username}`, config)
        if(!response.ok) {
            const responseJson = await response.json()
            return Promise.reject( await responseJson.message)
        }
    } catch (e) {
        return Promise.reject()
    }
}

export async function disableUser(username: string): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${userAuthTokens.accessToken}`
        }
    }

    try {
        await fetch(`${USER_ENDPOINT}/disable/${username}`, config)
    } catch (e) {
        return Promise.reject(e)
    }
}
export async function resendPW(username: string): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${userAuthTokens.accessToken}`
        }
    }

    try {
        await fetch(`${USER_ENDPOINT}/resendPassword?username=${username}`, config)
    } catch (e) {
        return Promise.reject(e)
    }
}

export async function enableUser(username: string): Promise<void> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${userAuthTokens.accessToken}`
        }
    }

    try {
        await fetch(`${USER_ENDPOINT}/enable/${username}`, config)
    } catch (e) {
        return Promise.reject(e)
    }
}
