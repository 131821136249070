import React, {useContext} from 'react';
import {Amplify} from 'aws-amplify'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {CircularProgress, ThemeProvider} from '@mui/material'

import './App.css';
import {AuthContext, AuthProvider} from "./hooks/AuthContext";
import {SnackbarProvider} from "./hooks/SnackbarContext";
import SignIn from "./components/Auth/SignIn/SignIn";
import theme from "./hooks/theme";
import CompleteRegistration from "./components/Auth/CompleteRegistration/CompleteRegistration";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import UserManagement from "./components/UserManagement/UserManagement";
import StoreManagement from "./components/StoreManagement/StoreManagement";
import Banner from './components/Banner/Banner';

const queryClient = new QueryClient()

function App() {
    Amplify.configure({
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
        identityPoolRegion: process.env.REACT_APP_COGNITO_USER_POOL_ID
    })

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <SnackbarProvider>
                        <AuthProvider>
                            <QueryClientProvider client={queryClient}>
                                <Banner/>
                                <Navigator/>
                            </QueryClientProvider>
                        </AuthProvider>
                    </SnackbarProvider>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

function Navigator() {
    const {currentUser, isInitializingCurrentUser} = useContext(AuthContext)
    if (isInitializingCurrentUser) { return <CircularProgress/> }
    if (currentUser) {
        return <React.Fragment>
            <NavigationBar />
            <Routes>
                <Route index={true} element={<UserManagement />} />
                <Route path='/UserManagement' element={<UserManagement />} />
                <Route path='/StoreManagement' element={<StoreManagement />} />
            </Routes>
        </React.Fragment>
    } else {
        return <Routes>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Route index={true} element={<SignIn/>}/>
            <Route path="/SignIn" element={<SignIn/>}/>
            {/*<Route path="/ForgotPassword" element={<ForgotPassword/>}/>*/}
            {/*<Route path="/ResetPassword" element={<ResetPassword/>}/>*/}
            <Route path="/Registration" element={<CompleteRegistration/>}/>
            <Route path="*" element={<SignIn/>}/>
        </Routes>
    }
}

export default App;
