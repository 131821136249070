import React, {useContext, useState} from 'react'
import TextField from "@mui/material/TextField";
import EditUserStyles from "../UserManagement/components/EditUser/EditUserStyles";
import {Button, Stack} from "@mui/material";
import {addStore} from "../../services/StoreService";
import UserTypeSelect, {UserType} from "../UserManagement/components/UserTypeSelect/UserTypeSelect";
import {SnackbarContext} from "../../hooks/SnackbarContext";

function StoreManagement() {
    return (
        <div>
            <AddStoreForm></AddStoreForm>
        </div>
    )
}


function AddStoreForm() {
    const [username, setUsername] = useState<string>("")
    const [storeNumber, setStoreNumber] = useState<string>("")
    const [userType, setUserType] = useState<UserType>("driver")

    const [usernameErrorMessage, setUsernameErrorMessage] = useState<string>('')
    const [storeNumberErrorMessage, setStoreNumberErrorMessage] = useState<string>('')
    const [userTypeErrorMessage] = useState<string>('')

    const {addSnack} = useContext(SnackbarContext)

    async function handleSend() {
        if(doValidation()){
            try{
                await addStore({storeNumber: storeNumber, userId: username, userType: userType})
                setUsername('')
                setStoreNumber('')
                setStoreNumberErrorMessage('')
                setUsernameErrorMessage('')
                addSnack({
                    severity: 'success',
                    message: `Store Added`,
                    action: null,
                })
            } catch(e: any) {
                addSnack({
                    severity: 'error',
                    message: `Error editing user: ${e}`,
                    action: null,
                })
            }
        }
    }

    function doValidation() {
        let isValid = true
        if(!username) {
            setUsernameErrorMessage("username can't be null")
            isValid = false
        }
        if(!storeNumber) {
            setStoreNumberErrorMessage("store number can't be null")
            isValid = false
        } else  if(storeNumber.length !== 9) {
            setStoreNumberErrorMessage("store number should be 9 characters ex. 5000001201")
            isValid = false
        }
        return isValid;
    }
    return <Stack width={'500px'} border={'1px solid grey'} padding={'40px'} margin={'10px'}>
        <h3>Add Store for user</h3>
        <TextField
            sx={EditUserStyles.editDriverTextField}
            inputProps={{'data-testid': 'username'}}
            type="text"
            label="Username*"
            error={!!usernameErrorMessage}
            helperText={usernameErrorMessage}
            value={username}
            onChange={(username) => setUsername(username.target.value)}
        />
        <TextField
            sx={EditUserStyles.editDriverTextField}
            inputProps={{'data-testid': 'storeNumber'}}
            type="text"
            label="Store Number*"
            error={!!storeNumberErrorMessage}
            helperText={storeNumberErrorMessage}
            value={storeNumber}
            onChange={(storeNumber) => setStoreNumber(storeNumber.target.value)}
        />
        <UserTypeSelect userType={userType} setUserType={setUserType}
                        userTypeErrorMessage={userTypeErrorMessage}/>
        <Button variant={'primary'}
                onClick={handleSend}
                sx={{width: '45%'}}>
            Send
        </Button>
    </Stack>
}

export default StoreManagement
