
import { useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'


const Banner = () => {

    const [displayBanner, setDisplayBanner] = useState(true)

    return (
        <Box bgcolor='#FFC72C' data-testid='banner' sx={{ display: displayBanner ? 'flex' : 'none', justifyContent: 'space-between' }}>
            <Typography data-testid='banner-text' variant='body1' p={1.25} pl={2.8} fontWeight='bold' textAlign='left' width='100%'>
               Your page has automatically been redirected to the new URL. Please bookmark the new URL before the systems stops automatic redirection on June 30th to avoid any unnecessary interruptions.
            </Typography>                    
            <IconButton data-testid='dismiss-icon' onClick={() => {setDisplayBanner(false)}}>
                <CloseIcon style={{ color: 'black', paddingRight: '32px' }} />
            </IconButton>
        </Box>
    )

}

export default Banner