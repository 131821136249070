const Colors = {
    white: '#fff',
    black: '#000',
    napaGreenDark: '#347F51',
    napaBlue: '#001489',
    napaBlue2: '#CCD0E7',
    napaBlue6: '#6672B8',
    napaBlueLink: '#0066CB',
    napaGreyDisabled: '#F4F4F4',
    napaGrey: '#707070',
    napaGrey1: '#F4F4F4',
    napaGrey2: '#333333',
    napaGrey3: '#474747',
    napaGrey4: '#565656',
    napaYellow: '#FFC72C',
    errorRed: '#d32f2f',
}

export default Colors
