import {Auth} from 'aws-amplify'
import {CurrentUser} from '../hooks/AuthContext'
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";

type SignInRequest = {
    username: string
    password: string
}

export type UserAuthTokens = {
    accessToken: string
    idToken: string
    refreshToken: string
}

//AWS Types suck, extend this as necessary
export type CognitoUserType = {
    attributes: CognitoUserAttributes,
    challengeName?: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'SELECT_MFA_TYPE' | 'MFA_SETUP' | 'PASSWORD_VERIFIER' | 'CUSTOM_CHALLENGE' | 'DEVICE_SRP_AUTH' | 'DEVICE_PASSWORD_VERIFIER' | 'ADMIN_NO_SRP_AUTH' | 'NEW_PASSWORD_REQUIRED'
}

type CognitoUserAttributes = Record<CognitoUserAttributeKey, string>
type CognitoUserAttributeKey = 'email' | 'sub'

export class AuthHelper {
    static async signIn(signInRequest: SignInRequest): Promise<CurrentUser> {
        try {
            //TODO: type responses when we switch to GCP
            const cognitoUser: CognitoUserType = await Auth.signIn({
                username: signInRequest.username,
                password: signInRequest.password
            })

            if (cognitoUser.challengeName ===  'NEW_PASSWORD_REQUIRED') {
                return Promise.reject({
                    message: cognitoUser.challengeName,
                    user: cognitoUser
                })
            }

            const currentUser = {
                id: cognitoUser.attributes.sub,
                email: cognitoUser.attributes.email,
            }

            return currentUser
        } catch (error: any) {
            switch (error.message) {
                case 'Incorrect username or password.':
                    return Promise.reject({
                        message: 'Incorrect username and/or password. Try again or Reset your password',
                        action: '/ResetPassword'
                    })
                case 'User is disabled.':
                    return Promise.reject({
                        message: 'This account has been disabled. Please contact terrapin@genpt.com or something'
                    })
                case 'Password attempts exceeded':
                    return Promise.reject({
                        message: 'Please try again later or click here to reset your password',
                        action: '/ForgotPassword'
                    })
                case 'New password required':
                    return Promise.reject({
                        reason: 'NEW_PASSWORD_REQUIRED',
                        user: error.user
                    })
                default:
                    return Promise.reject({message: 'Unknown Service Error'})
            }
        }
    }

    static async signOut() {
        try {
            await Auth.signOut()
        } catch (error: any) {
            console.error('Error signing out of cognito')
        }
    }

    static async completePasswordChallenge(user: CognitoUserType, newPassword: string) {
        try {
            await Auth.completeNewPassword(user, newPassword)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    static async currentSignedInUser(): Promise<CurrentUser> {
        try {
            const cognitoUser: CognitoUserType = await Auth.currentAuthenticatedUser()
            return {
                id: cognitoUser.attributes.sub,
                email: cognitoUser.attributes.email,
            }
        } catch (e) {
            return Promise.reject({
                message: 'No user has been Authenticated'
            })
        }
    }

    static async getUserAuthTokens(): Promise<UserAuthTokens> {
        try {
            const currentSession: CognitoUserSession = await Auth.currentSession()
            return {
                accessToken: currentSession.getAccessToken().getJwtToken(),
                idToken: currentSession.getIdToken().getJwtToken(),
                refreshToken: currentSession.getRefreshToken().getToken()
            }
        } catch (error: any) {
            return Promise.reject(error.message)
        }
    }
}