import {
    Box,
    Chip,
    FormControl,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import Select from "@mui/material/Select";
import React, {Dispatch, SetStateAction} from "react";
import {useStores} from "../../../../queries/Stores/useStores";

type StoreSelectProps = {
    storeList: string[],
    setStoreList: Dispatch<SetStateAction<string[]>>
}

function StoreSelect({storeList, setStoreList}: StoreSelectProps) {
    const { data: stores } = useStores()

    const handleChange = (event: SelectChangeEvent<string[]>): void => {
        const value = event.target.value
        setStoreList(typeof value === 'string' ? [value] : value)
    }

    const getUniqueStores = () => {
        let uniqueStores: Array<any> = [] // TODO: strong typing

        stores?.forEach(store => {
            if(!uniqueStores.includes(store.storeNumber)) {
                uniqueStores.push(store.storeNumber)
            }
        })

        uniqueStores.sort(function(a, b){return b - a})

        return uniqueStores
    }

    return (
            <FormControl fullWidth={true}>
                <Select
                    placeholder="Select Stores"
                    id="store-select"
                    multiple={true}
                    displayEmpty={true}
                    value={storeList}
                    onChange={handleChange}
                    renderValue={(selected) => (
                        selected?.length ? <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip onMouseDown={(e) => e.stopPropagation()} key={value} label={value} onClick={() => {
                                    setStoreList(storeList.filter(store => value !== store))
                                }} />
                            ))}
                        </Box> : <em>Select Stores</em>
                    )}
                >
                    {getUniqueStores().map((storeNumber: any) => (
                        <MenuItem
                            key={storeNumber}
                            value={storeNumber}
                        >
                            {storeNumber}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

    )
}

export default StoreSelect
