import Colors from "../../../../assets/Colors";

const EditUserStyles = {
    importAddUserButtonGrid: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    modalTitle: {
        height: '32px',
        fontFamily: 'NAPA SANS',
        fontWeight: '700',
        fontSize: '22px',
        lineHeight: '32px',
        letterSpacing: '0.4px',
        color: Colors.napaBlue
    },
    usernameSubheader: {
        height: '32px',
        fontFamily: 'NAPA SANS',
        fontWeight: '700',
        fontSize: '22px',
        lineHeight: '32px',
        letterSpacing: '0.4px',
        color: Colors.black
    },
    editDriverModalContainer: {
        width: '395px',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: Colors.white,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '3px'
    },
    editDriverModal: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        width: '100%'
    },
    editDriverTextField: {
        width: '100%',
        marginBottom: '10px',
        alignSelf: 'flex-start'
    },
    editDriverLabel: {
        fontFamily: 'NAPA SANS',
        fontWeight: '700',
        fontSize: '14px',
        letterSpacing: '0.36px',
        marginBottom: '5px',
        color: Colors.black,
        alignSelf: 'flex-start'
    },
    phoneNumberField: {
        border: '1px',
        borderStyle: 'solid',
        borderRadius: '4px',
        padding: '12px',
        width: '94%',
        textAlign: 'center',
        svg: {
            height: "20px",
        },
    }
}

export default EditUserStyles
