import {FormControl, FormHelperText, InputLabel, ListItemText, SelectChangeEvent} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, {Dispatch, SetStateAction} from "react";
import Colors from "../../../../assets/Colors";

const userTypes = ['driver', 'admin', ''] as const
export type UserType = typeof userTypes[number]

type UserTypeSelectProps = {
    userType: UserType
    setUserType: Dispatch<SetStateAction<UserType>>,
    userTypeErrorMessage: string
}

function UserTypeSelect({userType, setUserType, userTypeErrorMessage}: UserTypeSelectProps) {
    return (
        <FormControl fullWidth={true}>
            <InputLabel id='user-type-select-label'>Select User Type</InputLabel>
            <Select
                labelId='user-type-select-label'
                onChange={(e) => setUserType(e.target.value as UserType)}
                value={userType}
                label="Select User Type"
            >
                {userTypes?.map((type) => {
                    return type ? <MenuItem key={type} value={type}>
                        <ListItemText primary={type}/>
                    </MenuItem> : null
                })}
            </Select>
            <FormHelperText sx={{ color: Colors.errorRed}}>{userTypeErrorMessage}</FormHelperText>
        </FormControl>
    )
}

export default UserTypeSelect