import Colors from "../../../assets/Colors";

const styles = {
    forgotPasswordContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
    },
    forgotPasswordStack: {
        justifyContent: 'center',
        alignItems: 'center',
        spacing: 3,
        width: 400
    },
    logo: {
        width: 125,
        height: 125
    },
    header: {
        fontFamily: 'sans-serif',
        fontWeight: '400',
        color: Colors.napaGrey2,
        fontSize: 26,
        lineHeight: '28px'
    },
    subheader: {
        fontFamily: 'sans-serif',
        fontWeight: '400',
        color: Colors.napaGrey3,
        fontSize: 14,
        align: 'center',
        lineHeight: '19.5px',
        margin: '1rem 2.5rem'
    },
    passwordText: {
        fontFamily: 'NAPA SANS',
        fontStyle: 'normal',
        fontWeight: '400',
        color: Colors.napaGrey3,
        fontSize: 12,
        textAlign: 'left',
        lineHeight: '19.5px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.5rem'
    },

    submitContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    input: {
        fontSize: 14,
        fontWeight: '400',
        color: Colors.napaGrey,
        lineHeight: '22px'
    },
    submit: {
        textTransform: 'none',
        marginTop: '1rem'
    },
    login: {
        textTransform: 'none',
        width: 330,
        height: 48,
        marginTop: '1rem'
    },
    newUserLabel: {
        width: '310px',
        margin: '12px auto 6px auto',
        font: 'napa-sans-roman',
        color: '#333333',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '15px'
    },
    passwordContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0 1rem',
        width: '330px',
        fontWeight: 700
    },
    message: {
        width: 330,
        textAlign: 'left',
        padding: 0,
        margin: 0,
        fontSize: 12,
        color: '#A0171E',
        marginTop: -1
    }
}

export default styles
