import Colors from '../../assets/Colors'

export const navigationBarStyles = {
    navbarButtonStyle: {
        color: '#fff',
        margin: '0px 5px'
    },
    navBarMenuIcon: {
        color: Colors.napaYellow
    },
    navbarTitleStyle: {
        flexGrow: 1,
        display: { xs: 'none', sm: 'block' },
        textAlign: 'left',
        fontWeight: 'bold'
    },
    navBarContainer: {
        display: 'flex'
        //marginBottom: 5
    },
    navBarLogo: {
        paddingRight: 10,
        cursor: 'pointer',
        width: 45
    },
    navBarMenuGroup: {
        display: {
            xs: 'none',
            sm: 'block'
        }
    }
}
