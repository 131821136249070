import React, {useContext, useState} from 'react'
import {Box, Button, Grid, IconButton, Modal, Typography} from '@mui/material'
import {AttachFile, Clear} from '@mui/icons-material'
import {FileUploader} from 'react-drag-drop-files'
import Colors from '../../../../assets/Colors'
import {LoadingButton} from '@mui/lab'
import {SnackbarContext} from "../../../../hooks/SnackbarContext";
import {AuthContext} from "../../../../hooks/AuthContext";
import {batchImportUsers} from "../../../../services/UserManagementService";

const ImportUsers = () => {
    const {currentUser} = useContext(AuthContext)
    const {addSnack} = useContext(SnackbarContext)

    const [file, setFile] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showUserCreationModal, setUserCreationModal] = useState<boolean>(false)
    const [response, setResponse] = useState<String>('')

    function handleChange(file: File): void {
        setFile(file)
    }

    function handleClose(): void {
        setShowModal(false)
        setFile(null)
        setIsLoading(false)
    }

    function closeResultsModaal(): void{
        setUserCreationModal(false)
    }

    async function handleImport(): Promise<void> {
        if (currentUser && file) {
            setIsLoading(true)
            //TODO: Check for error response TER - 284
            try {
                const data: any = await batchImportUsers(file)
                setResponse(data.message)
                setShowModal(false)
                setUserCreationModal(true)
            } catch(e: any) {
                addSnack({
                    severity: 'error',
                    message: e.message,
                    duration: 4000
                })
            } finally {
                setIsLoading(false)
            }
        }
    }

    const dropArea = (
        <div style={dropAreaStyle}>
            <div style={dropAreaItem}>
                <AttachFile fontSize='medium'/>
            </div>
            <Typography sx={dropAreaText}>Select a CSV file to upload</Typography>
            <Typography sx={dropAreaItem}>or Drag and Drop it here</Typography>
        </div>
    )

    const handleOnDrop = (
        <div style={dropAreaStyle}>
            <div style={dropAreaItem}>
                <AttachFile fontSize='medium'/>
            </div>
            <Typography sx={dropAreaText}>
                {file?.name}
            </Typography>
        </div>
    )

    return (
        <Box>
            <Button sx={{marginX: '5px'}} variant={'secondary'} onClick={() => setShowModal(true)}>
                Import
            </Button>
            <Modal sx={modalStyle} open={showModal} onClose={handleClose}>
                <Grid container={true} sx={container}>
                    <Grid sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <Typography sx={titleStyle}>Import Users</Typography>
                        <IconButton onClick={handleClose}>
                            <Clear fontSize='medium'/>
                        </IconButton>
                    </Grid>
                    <Box>
                        <Typography sx={modalText}>
                            Upload up to XX drivers at the same time. Upload a file in CSV format that includes at
                            least the name, email, and default store for each driver.
                        </Typography>
                    </Box>
                    <Box sx={sampleCSVLink}>Download our sample CSV format</Box>
                    <Box sx={{margin: '20px 0', width: '100%'}}>
                        {file ? (
                            <FileUploader
                                hoverTitle=''
                                children={handleOnDrop}
                                name='importDrivers'
                                types={['CSV']}
                                label='Upload'
                            />
                           ) : (
                            <FileUploader
                                hoverTitle=' '
                                children={dropArea}
                                handleChange={handleChange}
                                name='importDrivers'
                                types={['CSV']}
                                label='Upload'
                            />
                        )}
                    </Box>
                    <Button variant={'secondary'} sx={{width: '45%', marginX: '9px'}} onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} sx={{width: '45%', marginX: '9px'}} variant={'primary'}
                                   onClick={handleImport}>
                        Import
                    </LoadingButton>
                </Grid>
            </Modal>
           {showUserCreationModal && 
            <Modal sx={modalStyle}  open={showUserCreationModal} onClose={closeResultsModaal}>
                <Grid container={true} sx={container}>
                    <Grid sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <Typography sx={titleStyle}>Users created</Typography>
                        <IconButton onClick={closeResultsModaal}>
                            <Clear fontSize='medium'/>
                        </IconButton>
                    </Grid>
                    <Box sx={{margin: '20px 0', width: '100%', overflow: 'auto', height: '250px'}}>
                    <Typography sx={responseFormat}> {response}</Typography>
                    </Box>
                    <Button variant={'primary'} sx={{ marginX: '9px'}} onClick={closeResultsModaal}>
                        Close
                    </Button>
                </Grid>
            </Modal>} 
        </Box>
    )
}

const modalStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%'
}

const responseFormat = {
    whiteSpace: 'pre-line'
}

const container = {
    width: '395px',
    alignItems: 'center',
    padding: '17px',
    backgroundColor: Colors.white
}

const titleStyle = {
    height: '32px',
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '25px',
    lineHeight: '32px',
    letterSpacing: '0.4px',
    color: Colors.napaBlue
}

const dropAreaStyle = {
    display: 'flex',
    flexFlow: 'column nowrap',
    border: '1.5px dashed #ADADAD',
    alignItems: 'center',
    paddingTop: '40px',
    paddingBottom: '10px'
}

const modalText = {
    fontFamily: 'NAPA SANS',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px',
    color: Colors.napaGrey2
}

const sampleCSVLink = {
    fontFamily: 'NAPA SANS',
    fontWeight: '400',
    fontSize: '16px',
    color: Colors.napaBlueLink,
    marginTop: '10px'
}

const dropAreaText = {
    fontFamily: 'NAPA SANS',
    fontWeight: '700',
    fontSize: '16px',
    color: Colors.napaBlueLink
}

const dropAreaItem = {
    fontFamily: 'NAPA SANS',
    fontWeight: '400',
    fontSize: '12px',
    color: Colors.napaGrey
}

export default ImportUsers