import {createContext, ReactElement, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {AuthHelper} from "../services/AuthHelper";

export const AuthContext = createContext<AuthContextType>({
    currentUser: null,
    isInitializingCurrentUser: true,
    signIn: () => {
    },
    signOut: () => {
    },
})

type AuthContextType = {
    currentUser: CurrentUser | null
    isInitializingCurrentUser: boolean
    signIn: (username: string, password: string) => void
    signOut: () => void
}

export interface CurrentUser {
    id: string,
    email: string
}

type AuthProviderProps = {
    children: ReactElement
}

export function AuthProvider({children}: AuthProviderProps): ReactElement {
    let navigate = useNavigate()

    const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null)
    const [isInitializingCurrentUser, setIsInitializingCurrentUser] = useState<boolean>(true)

    useEffect(() => {
        AuthHelper.currentSignedInUser()
            .then((user) => {
                setCurrentUser(user)
            }).catch((e) => console.error(e.message))
            .finally(() => setIsInitializingCurrentUser(false))
    }, [])

    async function signIn(username: string, password: string): Promise<void> {
        try {
            const currentUser: CurrentUser | null = await AuthHelper.signIn({username, password})
            setCurrentUser(currentUser)
            navigate('/')
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async function signOut(): Promise<void> {
        try {
            await AuthHelper.signOut()
        } catch (error: any) {
            console.error('Error signing out')
        } finally {
            setCurrentUser(null)
            navigate('/')
        }
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                isInitializingCurrentUser,
                signOut,
                signIn,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
