import React, {createContext, ReactElement, useCallback, useEffect, useState} from 'react'
import {Alert, Button, IconButton, Snackbar} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {v4 as uuidv4} from 'uuid'

export const SnackbarContext = createContext<SnackbarContextType>({
    addSnack: () => {
    },
    overrideSnacks: () => {
    }
})

type SnackbarContextType = {
    addSnack: (snack: Snack) => void
    overrideSnacks: (snack: Snack) => void
}

export type Snack = {
    severity: 'error' | 'success' | 'warning' | 'info'
    message: string
    action?: AlertAction | null
    key?: string
    duration?: number
}

type AlertAction = {
    callback: (...arg: any) => void
    prompt: string
}

type SnackbarProviderProps = {
    children: ReactElement
}

export const SnackbarProvider = ({children}: SnackbarProviderProps): ReactElement => {
    const [snackPack, setSnackPack] = useState<Snack[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [messageInfo, setMessageInfo] = useState<Snack | null>(null)

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            setMessageInfo({...snackPack[0]})
            setOpen(true)
        }
    }, [snackPack, messageInfo, open])

    function onClose(event: React.SyntheticEvent | Event, reason?: string): void {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
    }

    function handleExited(): void {
        setMessageInfo(null)
        setSnackPack((prev) => prev.slice(1))
    }

    const addSnack = useCallback((snack: Snack) => {
        setSnackPack((prev) => [...prev, {...snack, key: uuidv4()}])
    }, [])

    const overrideSnacks = useCallback((snack: Snack) => {
        setMessageInfo(null)
        setSnackPack([{...snack, key: uuidv4()}])
    }, [])

    function renderAlertAction(): ReactElement {
        if (messageInfo?.action) {
            return (
                <Button
                    color="inherit"
                    size="small"
                    style={{alignSelf: 'center'}}
                    onClick={messageInfo?.action.callback}
                >
                    {messageInfo?.action.prompt}
                </Button>
            )
        } else {
            return (
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setOpen(false)
                    }}
                >
                    <CloseIcon fontSize="inherit"/>
                </IconButton>
            )
        }
    }

    return (
        <SnackbarContext.Provider value={{addSnack, overrideSnacks}}>
            {children}
            <Snackbar
                key={messageInfo?.key}
                open={open}
                autoHideDuration={messageInfo?.duration}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                onClose={onClose}
                TransitionProps={{onExited: handleExited}}
            >
                <Alert severity={messageInfo?.severity} action={renderAlertAction()}>
                    {messageInfo?.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    )
}
