import {AuthHelper} from "./AuthHelper";


const STORE_ENDPOINT = `${process.env.REACT_APP_DELIVERYAPI_URL}/store`
type AddStoreRequest = {
    storeNumber: string,
    userId: string,
    userType: string
}

export async function getStores(): Promise<any[]> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${userAuthTokens.accessToken}`
        }
    }

    try {
        const response = await fetch(`${STORE_ENDPOINT}`, config)
        return await response.json()
    } catch (e) {
        return Promise.reject()
    }
}

export async function addStore(request: AddStoreRequest): Promise<any[]> {
    const userAuthTokens = await AuthHelper.getUserAuthTokens()
    const config = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${userAuthTokens.accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    }

    try {
        const response = await fetch(`${STORE_ENDPOINT}`, config)
        return await response.json()
    } catch (e) {
        return Promise.reject()
    }
}
