import {FormControl, FormHelperText, InputLabel, ListItemText} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React, {Dispatch, SetStateAction, useEffect} from "react";
import Colors from "../../../../assets/Colors";

type DefaultStoreSelectProps = {
    defaultStore: string,
    setDefaultStore: Dispatch<SetStateAction<string>>,
    defaultStoreErrorMessage: string,
    storeList: string[]
}

function DefaultStoreSelect({ defaultStore, setDefaultStore, defaultStoreErrorMessage, storeList} : DefaultStoreSelectProps) {
    useEffect(() => {
        if(!storeList.includes(defaultStore)) { setDefaultStore('') }
    }, [storeList])

    return (
        <FormControl fullWidth={true}>
            <Select
                onChange={(e) => setDefaultStore(e.target.value)}
                value={defaultStore}
                displayEmpty={true}
                label="Select User Type"
                renderValue={(selected) => selected ? <em>{selected}</em> : <em>Select a default store</em>}
            >
                {storeList?.length ? storeList.sort().map((store) => (
                    <MenuItem key={store} value={store}>
                        <ListItemText primary={store}/>
                    </MenuItem>
                )) : null}
            </Select>
            <FormHelperText sx={{ color: Colors.errorRed}}>{defaultStoreErrorMessage}</FormHelperText>
        </FormControl>
    )
}

export default DefaultStoreSelect