import {
    Box,
    Button,
    TextField,
    Typography,
    Stack,
    Container,
    InputAdornment,
    IconButton,
    Divider
} from '@mui/material'
import logo from '../../../assets/napa_logo.png'

import styles from './CompleteRegistrationStyles'

import { useNavigate } from 'react-router-dom'
import { useState, useContext } from 'react'
import NewUserPassword from './components/NewUserPassword/NewUserPassword'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {SnackbarContext} from "../../../hooks/SnackbarContext";
import {AuthHelper, CognitoUserType} from "../../../services/AuthHelper";

function CompleteRegistration() {
    const navigate = useNavigate()

    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const { addSnack } = useContext(SnackbarContext)

    const [cognitoUser, setCognitoUser] = useState<CognitoUserType>()
    const [viewPassword, setViewPassword] = useState<boolean >(false)

    function togglePassword() {
        setViewPassword(!viewPassword)
    }

    async function verifyPassword() {
        try {
            await AuthHelper.signIn({ username: email, password })
            navigate('/')
        } catch (error: any) {
            if (error.message === 'NEW_PASSWORD_REQUIRED') {
                setCognitoUser(error.user)
            } else {
                addSnack({
                    severity: 'error',
                    message: error.message,
                    action: error.action,
                    duration: 3000
                })
            }
        }
    }

    if (cognitoUser) { return <NewUserPassword user={cognitoUser} /> }

    return (
        <>
            <Container sx={styles.forgotPasswordContainer}>
                <Stack style={styles.forgotPasswordStack}>
                    <Box>
                        <img src={logo} alt={'napa logo'} style={styles.logo} />
                    </Box>
                    <Box>
                        <Typography style={styles.header} data-testid="new-text">
                            New User
                        </Typography>
                    </Box>
                    <Box>
                        <Typography style={styles.subheader} data-testid="reset-text">
                            Enter the temporary password sent to the email you provided the NAPA Delivery Tracker
                            team.
                        </Typography>
                    </Box>
                    <Box
                        width={{ xs: '300px', sm: '350px' }}
                        component={'form'}
                        noValidate={true}
                        sx={styles.submitContainer}
                    >
                        <TextField
                            autoComplete="false"
                            label={'Email*'}
                            value={email}
                            margin="normal"
                            inputProps={{ style: styles.input }}
                            sx={{
                                width: '330px',
                                height: '48px',
                                mb: '15px'
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            autoComplete="false"
                            type={viewPassword ? 'text' : 'password'}
                            label={'Temporary password*'}
                            value={password}
                            margin="normal"
                            data-testid="code"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => togglePassword()}>
                                            {viewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            inputProps={{ style: styles.input }}
                            sx={{
                                width: '330px',
                                height: '48px',
                                mb: '15px'
                            }}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <Button
                            onClick={verifyPassword}
                            variant="contained"
                            sx={styles.login}
                        >
                            Complete Registration
                        </Button>

                        <div style={styles.newUserLabel}>
                            <Divider style={styles.newUserLabel}> Already registered?</Divider>
                        </div>
                        <div>
                            <Button variant="secondary" data-testid="signin-btn" onClick={() => navigate('/')}>
                                Sign in
                            </Button>
                        </div>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

export default CompleteRegistration
