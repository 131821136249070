import {useContext} from "react";
import {SnackbarContext} from "../../hooks/SnackbarContext";
import {useQuery} from "react-query";
import {UserKeys} from "../keys";
import {getStores} from "../../services/StoreService";

export const useStores = () => {
    const { addSnack } = useContext(SnackbarContext)

    return useQuery(
        [UserKeys.GetStores],
        (context) => getStores(),
        {
            onError: (_err) => {
                addSnack({ severity: 'error', message: 'Error retrieving stores', duration: 3000 })
            },
            retry: (failureCount, error: any) => failureCount < 3 && error.status === 500,
            staleTime: 3000
        }
    )
}
