import React, {Dispatch, SetStateAction, useContext, useState} from 'react'
import {Button, Modal, Stack, Typography} from "@mui/material";
import Colors from "../../../../assets/Colors";
import {User} from "../../UserManagement";
import {disableUser, enableUser} from "../../../../services/UserManagementService";
import {useUsers} from "../../../../queries/Users/useUsers";
import {LoadingButton} from "@mui/lab";
import {SnackbarContext} from "../../../../hooks/SnackbarContext";

type DisableModalProps = {
    user: User,
    setUser: Dispatch<SetStateAction<User | null>>
}

function DisableModal({ user, setUser }: DisableModalProps) {
    const { refetch: refetchUsers } = useUsers()
    const { addSnack } = useContext(SnackbarContext)

    const [loading, setLoading] = useState<boolean>(false)

    function handleClose(): void {
        if(!loading) { setUser(null) }
    }

    async function handleAccept(): Promise<void> {
        setLoading(true)
        if(user) {
            try {
                user.enabled ? await disableUser(user.username) : await enableUser(user.username)
                await refetchUsers()
                addSnack({
                    severity: 'success',
                    message: `User successfully ${user.enabled ? 'disabled': 'enabled'}`,
                    duration: 4000
                })
                handleClose()
            } catch(e: any) {
                addSnack({
                    severity: 'error',
                    message: e.message,
                    duration: 4000
                })
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <Modal open={!!user} onClose={handleClose} sx={{justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'}}>
            <Stack spacing={3} sx={{
                width: 450,
                alignItems: 'center',
                padding: '16px',
                backgroundColor: Colors.white,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '3px'
            }}>
                <Typography variant={'h5'}>{user?.enabled ? 'Disable' : 'Enable'} {user?.email}?</Typography>
                <Stack direction={'row'} spacing={2}>
                    <Button variant={'secondary'} onClick={handleClose} sx={{ width: 200 }}>Cancel</Button>
                    <LoadingButton variant={'primary'} onClick={handleAccept} loading={loading} sx={{ width: 200}}>{user?.enabled ? 'Disable' : 'Enable'}</LoadingButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default DisableModal