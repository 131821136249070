import { useContext } from 'react'
import { useQuery } from 'react-query'
import { UserKeys } from '../keys'
import {SnackbarContext} from "../../hooks/SnackbarContext";
import { getUsers } from "../../services/UserManagementService";

export const useUsers = () => {
    const { addSnack } = useContext(SnackbarContext)

    return useQuery(
        [UserKeys.GetUsers],
        (context) => getUsers(),
        {
            onError: (_err) => {
                addSnack({ severity: 'error', message: 'Error retrieving users', duration: 3000 })
            },
            retry: (failureCount, error: any) => failureCount < 3 && error.status === 500,
            staleTime: 3000
        }
    )
}
